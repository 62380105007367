var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"新建","width":680,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":24}},[(false)?_c('a-form-item',{attrs:{"label":"电表记录批次"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'batch2',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]),expression:"[\n                'batch2',\n                {\n                  rules: [\n                    { required: true, message: '' },\n                  ],\n                },\n              ]"}],attrs:{"option-label-prop":"tt","show-arrow":false,"filter-option":false,"not-found-content":null,"show-search":"","allow-clear":"","placeholder":"请输入批次","default-active-first-option":false},on:{"search":this.corporationSearch,"change":_vm.handlecorporationChange}},_vm._l((_vm.dataSource),function(d){return _c('a-select-option',{key:d.batch,attrs:{"tt":d.batch}},[_vm._v(" "+_vm._s(d.batch)+" ")])}),1)],1):_vm._e(),_c('a-form-item',{attrs:{"label":"批次"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['batch', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]),expression:"['batch', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]"}]})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }